import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import GridRollItem from "../grid-roll-item/grid-roll-item"
import Modal from "../modal/modal"
import ImageGallery from "../image-gallery/image-gallery"

import cn from "./grid-roll.module.scss"

const GridRoll = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [collectionId, setCollectionId] = useState("")

  const data = useStaticQuery(graphql`
    query Illustrations {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "single-illustration" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            id
            frontmatter {
              coverimage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                  id
                }
              }
              additionalImages {
                childImageSharp {
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                  }
                  id
                }
              }
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const illustrations = data.allMarkdownRemark.edges

  const clickEventHandler = id => {
    if (!isVisible) {
      setIsVisible(true)
      setCollectionId(id)
    } else {
      setIsVisible(false)
    }
  }

  const keyDownEventHandler = e => {
    if (e.code === "Escape") {
      setIsVisible(false)
    }
  }

  return (
    <>
      <div className={cn.content}>
        {illustrations &&
          illustrations.map(({ node: illustration }) => {
            return (
              <GridRollItem
                clicked={clickEventHandler}
                keyDown={keyDownEventHandler}
                key={illustration.id}
                collectionId={illustration.id}
                imgData={
                  illustration.frontmatter.coverimage.childImageSharp.fluid
                }
                title={illustration.frontmatter.title}
              />
            )
          })}
      </div>
      {isVisible && (
        <Modal clicked={clickEventHandler} keyDown={keyDownEventHandler}>
          <ImageGallery
            collections={illustrations}
            collectionId={collectionId}
          />
        </Modal>
      )}
    </>
  )
}

export default GridRoll
