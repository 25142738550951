import React, { useEffect } from "react"
import PropTypes from "prop-types"

import cn from "./modal.module.scss"

const Modal = ({ clicked, children, keyDown }) => {
  useEffect(() => {
    document.addEventListener("keydown", keyDown)

    return () => {
      document.removeEventListener("keydown", keyDown)
    }
  }, [keyDown])

  return (
    <div className={cn.modal}>
      <input onClick={clicked} className={cn.closeBtn} type="button"></input>
      {children}
    </div>
  )
}

Modal.propTypes = {
  clicked: PropTypes.func.isRequired,
  keyDown: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default Modal
