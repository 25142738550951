import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import cn from "./grid-roll-item.module.scss"

const GridRollItem = ({ imgData, title, collectionId, clicked }) => {
  const keyDownHandler = e => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault()
      clicked(collectionId)
    }
  }

  return (
    <div
      onClick={() => clicked(collectionId)}
      onKeyDown={keyDownHandler}
      className={cn.illustrationWrapper}
      tabIndex="0"
      role="menuitem"
    >
      <Img fluid={imgData} alt={title} className={cn.illustration} />
      <div className={cn.backSide}>
        <p className={cn.title}>{title}</p>
      </div>
    </div>
  )
}

GridRollItem.propTypes = {
  imgData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  collectionId: PropTypes.string.isRequired,
  clicked: PropTypes.func.isRequired,
}

export default GridRollItem
