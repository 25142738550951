import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"

import cn from "./image-gallery.module.scss"

const ImageGallery = ({ collections, collectionId }) => {
  const [imgIndex, setImgIndex] = useState(0)
  const smallImages = document.getElementsByClassName(`${cn.smallImageWrapper}`)
  const images = useMemo(
    () =>
      collections.find(({ node }) => node.id === collectionId).node.frontmatter
        .additionalImages,
    [collections, collectionId]
  )

  useEffect(() => {
    for (let image of smallImages) {
      image.addEventListener("keydown", keyDownHandler)
    }

    return () => {
      for (let image of smallImages) {
        image.removeEventListener("keydown", keyDownHandler)
      }
    }
  }, [smallImages])

  const bigImageEventHandler = e => {
    e.preventDefault()
    if (e.clientX <= window.innerWidth / 2) {
      imgIndex <= 0 ? setImgIndex(images.length - 1) : setImgIndex(imgIndex - 1)
    } else {
      imgIndex < images.length - 1 ? setImgIndex(imgIndex + 1) : setImgIndex(0)
    }
  }

  const smallImageEventHandler = e => {
    e.preventDefault()
    setImgIndex(
      parseInt(
        e.target.parentElement.parentElement.parentElement.dataset.indexNumber
      )
    )
  }

  const keyDownHandler = e => {
    if (e.code === "Enter" || e.code === "Space") {
      setImgIndex(parseInt(e.target.dataset.indexNumber))
    }
  }

  return (
    <div className={cn.gallery}>
      <div
        id="big-image"
        onClick={bigImageEventHandler}
        className={cn.coverImageWrapper}
        aria-hidden
      >
        <Img
          objectFit="contain"
          fluid={images[imgIndex].childImageSharp.fluid}
          className={cn.coverImage}
        />
        <i className={cn.leftArrow}></i>
        <i className={cn.rightArrow}></i>
      </div>
      <div className={cn.smallImages}>
        {images &&
          images.map((image, i) => {
            return (
              <div
                key={image.childImageSharp.id}
                onClick={smallImageEventHandler}
                onKeyDown={keyDownHandler}
                className={cn.smallImageWrapper}
                data-index-number={i}
                role="menuitem"
                tabIndex="0"
              >
                <Img
                  fluid={image.childImageSharp.fluid}
                  alt={`Image ${i}`}
                  className={cn.image}
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}

ImageGallery.propTypes = {
  collections: PropTypes.array.isRequired,
  collectionId: PropTypes.string.isRequired,
}

export default ImageGallery
