import React from "react"

import Layout from "../components/layout/layout"
import GridRoll from "../components/grid-roll/grid-roll"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <GridRoll />
  </Layout>
)

export default IndexPage
